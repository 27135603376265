/* @jsx jsx */
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'

const propTypes = {
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  fluid: PropTypes.object,
  src: PropTypes.string,
}

const defaultProps = {
  objectFit: `cover`,
  objectPosition: `center`,
  alt: ``,
}

const Image = ({ fluid, objectFit, objectPosition, alt, ...props }) =>
  fluid && (
    <GatsbyImage
      image={fluid}
      alt={alt}
      sx={{
        objectFit: objectFit,
        objectPosition: objectPosition,
        width: `100%`,
        height: `100%`,
      }}
      imgStyle={{
        ...props,
      }}
    />
  )

Image.propTypes = propTypes
Image.defaultProps = defaultProps

export default Image
