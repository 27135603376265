import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Header from './Header'
import Footer from './Footer'
//import typography from '../utils/typography'
//import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      sx={{
        margin: `0 auto`,
        pt: 4,
        px: [2, 3],
        pb: 3,
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  line: PropTypes.bool,
}

Layout.defaultProps = {
  line: false,
}

export default Layout
