import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useResponsiveValue } from './useResponsiveValue'
import Logo from '@fourforbusiness/corporate-brand-logo'
import Container from './Container'
import Flex from './Flex'
import Heart from './Heart'

const propTypes = {}
const defaultProps = {}

const Footer = () => {
  const logoSize = useResponsiveValue([20, 24, 28, 28])

  return (
    <Container
      as="footer"
      large
      sx={{
        mt: [4, , 6],
        mb: 0,
        p: 0,
        pb: 0,
        borderTop: `1px solid`,
        borderColor: `primary`,
      }}
    >
      <Flex
        smallplus
        sx={{
          p: `0 5vw`,
          pt: 3,
          justifySelf: `center`,
          alignItems: `flex-end`,
        }}
      >
        <div sx={{ variant: 'styles.footerText' }}>
          {` `}© {new Date().getFullYear()}, Friederike E.L. Vilmar
        </div>
        <div sx={{ mx: 'auto' }} />
        <Link to="/kontakt/" activeClassName="active" sx={{ variant: 'styles.footerLink' }}>
          {` `}Kontakt{` `}
        </Link>
        <Link to="/impressum/" activeClassName="active" sx={{ variant: 'styles.footerLink' }}>
          {` `}Impressum{` `}
        </Link>
        <Link to="/datenschutz/" activeClassName="active" sx={{ variant: 'styles.footerLink' }}>
          {` `}Datenschutz{` `}
        </Link>
      </Flex>
    </Container>
  )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

export default Footer
