/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { jsx } from 'theme-ui'
import Logo from './Logo'
import { useResponsiveValue } from './useResponsiveValue'
import Container from './Container'
import Button from './Button'

const propTypes = {
  linkStyle: PropTypes.string,
}
const defaultProps = {
  linkStyle: `navlink`,
}

const Header = () => {
  const [menu, updateMenu] = React.useState(false)
  const logoSize = useResponsiveValue([40, 40, 48, 56, 64])
  const iconState = useResponsiveValue([true, true, false])
  const burgerMenu = useResponsiveValue([true, true, false], {
    defaultIndex: 1,
  })
  const showMenu = () => updateMenu(!menu)
  const linkStyle = burgerMenu ? `burgerlink` : `navlink`

  const Links = ({ linkStyle }) => (
    <>
      {burgerMenu && (
        <Link to="/" activeClassName="active" sx={{ variant: `styles.${linkStyle}` }}>
          Home
        </Link>
      )}
      <Link
        to="/leistungen/"
        activeClassName="active"
        partiallyActive={true}
        sx={{ variant: `styles.${linkStyle}` }}
      >
        Leistungen
      </Link>
      <Link
        to="/vorgehensweise/"
        activeClassName="active"
        partiallyActive={true}
        sx={{ variant: `styles.${linkStyle}` }}
      >
        Vorgehensweise
      </Link>
      <Link
        to="/anwalt/"
        activeClassName="active"
        partiallyActive={true}
        sx={{ variant: `styles.${linkStyle}` }}
      >
        Anwalt
      </Link>
      <Link
        to="/presse/"
        activeClassName="active"
        partiallyActive={true}
        sx={{ variant: `styles.${linkStyle}` }}
      >
        Presse
      </Link>
      <Link
        to="/kontakt/"
        activeClassName="active"
        partiallyActive={true}
        sx={{ variant: `styles.${linkStyle}` }}
      >
        Kontakt
      </Link>
    </>
  )

  return (
    <Container as="header" fullscreen>
      {burgerMenu && menu && (
        <div
          sx={{
            position: `absolute`,
            top: 0,
            left: 0,
            width: `100%`,
            height: `100%`,
            bg: `background`,
            py: 2,
            pt: `94px`,
            px: 2,
            zIndex: 10,
          }}
        >
          <div
            sx={{
              display: `flex`,
              width: `100%`,
              height: `100%`,
              bg: `primary`,
              p: 4,
              flexDirection: `column`,
              justifyContent: `center`,
              alignItems: `flex-end`,
            }}
          >
            <Links linkStyle={linkStyle} />
          </div>
        </div>
      )}
      <div
        sx={{
          maxWidth: `container.smallplus`,
          px: `5vw`,
          mx: `auto`,
          mb: 2,
          justifySelf: `center`,
          display: `flex`,
          alignItems: `flex-end`,
        }}
      >
        <Link
          to="/"
          sx={{
            variant: 'styles.navlink',
            m: 0,
            p: 0,
            fill: 'primary',
            zIndex: 20,
          }}
        >
          <Logo width={logoSize} icon={iconState} />
        </Link>
        <div sx={{ mx: 'auto' }} />
        {burgerMenu || <Links linkStyle={linkStyle} />}
        {burgerMenu && (
          <Button
            onClick={() => showMenu()}
            variant={`small`}
            sx={{
              mb: 2,
              ml: 4,
              zIndex: 20,
            }}
          >
            Men&uuml;
          </Button>
        )}
      </div>
    </Container>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
