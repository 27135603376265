import React from 'react'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  variant: PropTypes.string,
}
const defaultProps = {
  children: null,
  variant: `default`,
}

const Wrapper = styled('button')({})

const Button = ({ children, variant, ...props }) => {
  return (
    <Wrapper {...props} sx={{ variant: `buttons.${variant}` }}>
      {children}
    </Wrapper>
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
