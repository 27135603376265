import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
/** @jsx jsx */
import { jsx } from 'theme-ui'

const Logo = ({ icon, width }) => {
  const viewBoxWidth = width //icon ? '44' : '275'
  const StyledSvg = styled('svg')({})

  return (
    <StyledSvg
      sx={{ width: width }}
      //viewBox={`0 0 ${viewBoxWidth} 184`}
      //height="184px"
      viewBox="0 0 149 184"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon id="path-1" points="0 0 149 0 149 184 0 184"></polygon>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Logo-weiss" transform="translate(-479.000000, -328.000000)">
          <g id="Group-3" transform="translate(479.000000, 328.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path
              d="M3.8692474,48.7561226 C1.4745414,48.7561226 -0.34751752,50.9282163 0.0562275994,53.3193938 C2.94144343,70.4266817 16.4501304,131.362462 66.4219761,178.18549 L71.128094,182.594114 C73.1283098,184.468629 76.2148198,184.468629 78.2161924,182.594114 L82.9211534,178.18549 C140.539862,124.198301 146.815843,64.7035546 147.055314,62.1975631 L147.844294,53.912209 C148.110373,51.1250403 145.947039,48.713946 143.183294,48.713946 L72.7789372,48.713946 C71.5052313,48.713946 70.3749763,47.8598704 70.0371724,46.6156614 C68.1526429,39.6623842 66.777133,32.4396455 65.7833879,27.0457301 C65.52078,25.6210991 66.6012899,24.3136252 68.0311723,24.3147968 L145.320019,24.3757185 C147.351471,24.3768901 149,22.7097437 149,20.6512925 L149,3.75254438 C149,1.6964363 147.353784,0.0281184044 145.322333,0.0281184044 L41.6732101,0 C39.9089944,0 38.5057198,1.49609757 38.5994257,3.28040105 L39.1477786,13.8304029 C39.3155237,16.0282712 43.4640338,67.2189188 74.0503295,116.714302 C75.0267217,118.293581 77.2698786,118.358017 78.3677414,116.864263 C82.5012123,111.237205 86.6948399,103.302151 88.9912126,98.7236495 C89.7859773,97.1385132 89.7362322,95.2675134 88.8905655,93.7081517 C85.7624085,87.9323039 82.941977,82.0826471 80.5021533,76.4602752 C79.8115062,74.8645948 80.9614279,73.0779481 82.680526,73.0779481 L116.952587,73.0779481 C118.60343,73.0779481 119.782273,74.6818296 119.3149,76.2845395 C114.393606,93.1750866 103.096841,120.800744 78.068114,148.316274 C76.2576237,150.305602 73.1410353,150.283342 71.3536823,148.274097 C37.997857,110.784979 27.7249153,66.6143878 25.2203073,52.7043188 C24.8084642,50.4138964 22.8394837,48.7561226 20.5407973,48.7561226 L3.8692474,48.7561226 Z"
              id="Fill-1"
              fill="#000000"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </StyledSvg>
  )
}

export default Logo
