//import React from 'react'
//import PropTypes from 'prop-types'
//import { css } from '@emotion/react'
//import styled from '@emotion/styled'
//import { compose, space, layout, color, flexbox } from 'styled-system'
//import css from '@styled-system/css'
//import shouldForwardProp from '@styled-system/should-forward-prop'
//* @jsx jsx */
//import { jsx } from '@styled-system/jsx'
//import { widthUnits } from '../gatsby-plugin-theme-ui/'

import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
/* @jsx jsx */
import { jsx } from 'theme-ui'
//import css from '@styled-system/css'

const propTypes = {
  /** Coverts the container to a flexbox one, which orders tags or components in a row */
  tile: PropTypes.bool,
  /** Coverts the container to a grid container */
  grid: PropTypes.bool,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  reference: PropTypes.bool,
  referencesmall: PropTypes.bool,
  funkyGrid: PropTypes.bool,
  fullscreen: PropTypes.bool,
  large: PropTypes.bool,
  smallplus: PropTypes.bool,
  bg: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  tile: false,
  grid: false,
  maxWidth: '960px',
  margin: '0 auto',
  padding: '3rem 5vw 1.5rem',
  reference: false,
  referencesmall: false,
  fullscreen: false,
  large: false,
  smallplus: false,
  bg: 'transparent',
  children: null,
}

const Container = props => {
  const gridStyle = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    //grid-template-rows: repeat(auto-fit, minmax(120px , 180px));
    grid-auto-flow: dense;
    //${props.minHeight && `grid-auto-rows: ${props.minHeight}`}
    grid-gap: 10px;
    @media (min-width: 600px) {
      grid-gap: 20px;
    }
    @media (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    ${props.reference &&
      `
      @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
      @media (min-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }
    `}
    ${props.referencesmall &&
      `
      @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
      @media (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
      }
    `}
    ${props.fullscreen &&
      `
      @media (min-width: 1500px) {
        grid-template-columns: repeat(3, 1fr);
      }
    `}
  `
  const tileStyle = css`
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
  `
  const Wrapper = styled.div`
    ${props.grid && gridStyle}
    ${props.tile && tileStyle}
    max-width: ${(props.fullscreen && '100%') ||
      (props.large && 'calc(1200px + 20vw)') ||
      (props.smallplus && 'calc(960px + 10vw)') ||
      props.maxWidth};
    ${props.height && `height: ${props.height};`}
    margin: ${props.margin};
    padding: ${(props.fullscreen && '0') ||
      (props.large && props.padding) ||
      (props.smallplus && props.padding) ||
      props.padding};
    background-color: ${props.bg};
    z-index: -2; // from Hero
    overflow: hidden;
  `

  return (
    <Wrapper
      sx={
        {
          //pt: [3,4,5],
        }
      }
      {...props}
    >
      <>{props.children}</>
    </Wrapper>
  )
}

Container.propTypes = propTypes
Container.defaultProps = defaultProps

export default Container
